const images = require.context('../images', true)
import RailsUjs from "@rails/ujs"
import Turbolinks from "turbolinks"
RailsUjs.start()
Turbolinks.start()
import "channels"
import $ from 'jquery';
global.$ = $;
global.jQuery = $;
import 'bootstrap/dist/js/bootstrap';
import 'bootstrap/dist/css/bootstrap';
$(document).on('turbolinks:load', function () {
  $('body').tooltip({
    selector: '[data-toggle="tooltip"]',
    container: 'body',
  });

  $('body').popover({
    selector: '[data-toggle="popover"]',
    container: 'body',
    html: true,
    trigger: 'hover',
  });
});
import '../src/sbadmin2/sb-admin-2.scss';
